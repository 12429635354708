<template>
  <div>
    <b-button variant="secondary" type="submit" class="mr-1" @click.prevent="visible = true" v-if="false">
      <feather-icon icon="PlusIcon" class="mr-25" /> New Fieldset
    </b-button>
    <b-modal
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-model="visible"
      centered
      hide-header
      size="md"
      cancel-variant="outline-secondary"
      ok-title="Ok"
      cancel-title="Close"
      @ok="validateForm"
      @hidden="reset"
    >
      <b-card-body>
        <b-row>
          <b-col class="cursor-pointer">
            <validation-observer ref="fieldsetForm">
              <b-form>
                <b-form-group label="Name *">
                  <validation-provider #default="{ errors }" name="Name" rules="required">
                    <b-form-input id="name" placeholder="" size="md" v-model="fieldset.name" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <b-form-text>The Name of the fieldset</b-form-text>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Descriptions">
                  <b-form-input id="description" placeholder="" size="md" v-model="fieldset.description" />
                  <b-form-text>The Description of the fieldset</b-form-text>
                </b-form-group>
                <b-form-group>
                  <div class="inline-spacing">
                    <b-form-checkbox id="collapsed" v-model="fieldset.collapsed" class="custom-control-primary">
                      Collapsed
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-card-body>
    </b-modal>
  </div>
</template>
<script>
import { required } from '@validations'
import { generateRandomString } from '@core/utils/utils'

export default {
  name: 'NewFieldset',
  props: ['value'],
  components: {},
  data() {
    return {
      required,
      fieldset: {},
      visible: false,
    }
  },
  created() {
    this.newFieldset()
  },
  computed: {},
  methods: {
    async validateForm(e) {
      e.preventDefault()
      var self = this
      return await self.$refs.fieldsetForm.validate().then(success => {
        if (success) {
          this.visible = false
          this.$emit('fieldset-created', { ...this.fieldset, key: generateRandomString(8, '12345678') })
          return true
        } else {
          return false
        }
      })
    },
    reset() {
      this.newFieldset()
    },
    newFieldset() {
      this.fieldset = { key: '', name: '', description: '', collapsed: false, fields: [] }
    },
  },
}
</script>
