<template>
  <div v-if="internal">
    <b-card no-body>
      <b-card-body>
        <b-row align-v="center">
          <b-col lg="4" md="12" class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-body class="mt-75 ml-75">
                <div class="text-center">
                  <div class="pb-2">
                    <b-avatar size="100" alt="" class="badge-minimal bg-secondary bg-lighten-3">
                      <FeatherIcon icon="UserIcon" size="64" />
                    </b-avatar>
                  </div>
                  <!-- <b-link variant="primary" size="sm" class="mb-75 mr-75" @click="$refs.refInputEl.$el.click()">
                    Upload
                  </b-link>
                  <b-form-file ref="refInputEl" accept=".jpg, .png, .gif" :hidden="true" plain />

                  <b-link variant="outline-secondary" size="sm" class="mb-75 mr-75">
                    Reset
                  </b-link>
                  <b-card-text class="font-small-2">Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text> -->
                </div>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col lg="8" md="12" class="text-center text-md-left">
            <ProfileCardInfo :data="account" />
            <div class="mt-1">
              <b-button variant="primary" class="mr-25" size="sm" @click="editModal = true">Edit</b-button>
              <b-button variant="outline-secondary" size="sm" @click="changePasswordModal = true">Change Password</b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-container fluid class="p-0"> </b-container>
    <b-modal
      v-model="editModal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-header
      centered
      size="lg"
      @hidden="resetEdit"
      @ok="onSave"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Cancel"
    >
      <div class="px-1 pt-1">
        <b-media class="d-flex align-items-center mb-1">
          <template #aside>
            <b-avatar size="50" alt="" class="mb-0 field-avatar">
              <feather-icon class="text-dark" icon="UserIcon" size="18" />
            </b-avatar>
          </template>
          <div>
            <h4 class="media-heading mb-0">
              Edit Account
            </h4>
            <b-card-text>
              Edit your account information.
            </b-card-text>
          </div>
        </b-media>
        <hr class="mt-0 mb-2" />
        <form-alert :errors="serverErrors" />
        <validation-observer ref="accountForm">
          <b-form v-on:keyup.enter="onSave" @submit.prevent>
            <b-row>
              <!-- names -->
              <b-col cols="6">
                <text-input v-model="internal.firstName" placeholder="First Name" label="First Name" name="First Name" validation-rules="required" />
              </b-col>

              <b-col cols="6">
                <text-input v-model="internal.lastName" placeholder="Last Name" label="Last Name" name="Last Name" validation-rules="required" />
              </b-col>

              <!-- company -->
              <b-col cols="6">
                <text-input v-model="internal.company" placeholder="Company" label="Company" name="Company" validation-rules="" />
              </b-col>

              <!-- job type -->
              <b-col cols="6">
                <b-form-group label-for="jobtype" label="Job Type">
                  <validation-provider #default="{ errors }" name="Job Type" rules="required">
                    <b-form-select v-model="internal.jobType" :options="jobTypes" size="md" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
    <b-modal
      v-model="changePasswordModal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-header
      centered
      size="md"
      @hidden="resetChangePassword"
      @ok="onChangePassword"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Cancel"
    >
      <div class="d-block text-left mt-2">
        <b-media class="d-flex align-items-center mb-1">
          <template #aside>
            <b-avatar size="50" alt="" class="mb-0 field-avatar">
              <feather-icon class="text-dark" icon="KeyIcon" size="18" />
            </b-avatar>
          </template>
          <div>
            <h4 class="media-heading mb-0">
              Change Password
            </h4>
            <b-card-text>
              Set your new password in the form below.
            </b-card-text>
          </div>
        </b-media>
        <hr class="mt-0 mb-2" />
        <form-alert :errors="serverErrors" />
        <validation-observer ref="changePasswordForm">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <text-input
                  v-model="newPassword.password"
                  placeholder="Password"
                  label="New Password"
                  validation-rules="required"
                  type="password"
                  vid="password"
                />
              </b-col>

              <b-col cols="12">
                <text-input
                  v-model="newPassword.confirm"
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  validation-rules="required|confirmpassword:password"
                  type="password"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import DefaultNotifications from '@/components/Notification/default'
import { accounts } from '@/api/index'
import { extend } from 'vee-validate'
import { confirmed } from 'vee-validate/dist/rules'
import FormComponent from '@/mixins/FormComponent'
import ProfileCardInfo from '@/components/Profile/ProfileCardInfo.vue'

extend('confirmpassword', {
  ...confirmed,
  message: 'The passwords do not match',
})

export default {
  name: 'ProfileAccount',
  props: ['account'],
  components: { ProfileCardInfo },
  mixins: [FormComponent],
  data() {
    return {
      editModal: false,
      changePasswordModal: false,
      internal: null,
      newPassword: {
        password: '',
        confirm: '',
      },
      jobTypes: [
        { value: '', text: 'Choose..' },
        { value: 'Director', text: 'Director' },
        { value: 'Developer/Engineering/Programming', text: 'Developer/Engineering/Programming' },
        { value: 'Graphic/UI/UX Designer', text: 'Graphic/UI/UX Designer' },
        { value: 'Marketing', text: 'Marketing' },
        { value: 'Product Owner/Project Manager', text: 'Product Owner/Project Manager' },
        { value: 'Other', text: 'Other' },
      ],
    }
  },
  watch: {
    account: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.internal = this.$_.cloneDeep(newValue)
        }
      },
    },
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    async onSave(e) {
      e.preventDefault()

      const self = this

      const validation = await self.$refs.accountForm.validateWithInfo()

      if (validation.isValid) {
        accounts
          .updateMe(self.internal)
          .then(res => {
            self.ResetErrors()
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.editModal = false
            self.$emit('update')
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.clientValidationErrors = validation
      }
    },
    resetEdit() {
      const self = this
      self.internal = self.$_.cloneDeep(self.account)
    },
    async onChangePassword(e) {
      e.preventDefault()

      const self = this

      const validation = await self.$refs.changePasswordForm.validateWithInfo()

      if (validation.isValid) {
        const data = { password: self.newPassword.password }

        accounts
          .changeMyPassword(data)
          .then(res => {
            self.ResetErrors()
            self.$bus.$emit('notification', { ...DefaultNotifications.saveSuccessful, text: 'Password changed!' })
            self.changePasswordModal = false
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.clientValidationErrors = validation
      }
    },
    resetChangePassword() {},
  },
}
</script>
