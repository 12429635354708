<template>
  <div>
    <b-container fluid class="p-0">
      <b-card no-body class="pb-0">
        <b-card-body class="pb-0">
          <b-card-title>
            Projects
          </b-card-title>
        </b-card-body>
        <b-table v-if="projects" responsive class="position-relative" :items="projects" :fields="columns" no-local-sorting>
          <template #cell(displayName)="data">
            <b-link :to="{ name: 'Dashboard', params: { project: data.item.name } }" class="font-weight-bolder">{{ data.item.displayName }}</b-link>
          </template>
          <template #cell(role)="data">
            {{ getRole(data.item) }}
          </template>
          <template #cell(plan)="data">
            {{ data.item.plan.name }}
          </template>
          <template #cell($actions)="data">
            <b-button
              variant="outline-danger"
              size="sm"
              class="btn-icon rounded-circle"
              @click="onDisconnectConfirm(data.item)"
              v-if="!isProjectOwner(data.item)"
            >
              <feather-icon icon="UserXIcon" v-b-tooltip.hover.v-secondary.top v-b-tooltip.hover.html="`Disconnect from project`" />
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-container>
    <b-modal id="disconnectConfirmModal" hide-footer title="User" size="md" centered hide-header v-model="confirmDisconnectAccount" v-if="selectedProject">
      <div class="d-block text-left mt-2">
        <h4>Disconnect Membership</h4>
        <p class="pt-2 h6 font-weight-normal">
          You are disconnecting your account from <strong>{{ selectedProject.displayName }}</strong
          >. You will not be able to work on the project anymore.
        </p>
        <p class="pt-2 h6 font-weight-normal">
          If you want to join the project again in the future you need to ask the project's owner to invite you.
        </p>
        <p class="pt-1 h6 font-weight-normal">Do you want to disconnect from the project?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDisconnectAccount = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDisconnectMembership">Disconnect</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import DefaultNotifications from '@/components/Notification/default'
import { projects, memberships } from '@/api/index'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'ProfileProjects',
  props: ['account'],
  components: {},
  mixins: [FormComponent],
  data() {
    return {
      loading: false,
      projects: null,
      roles: [],
      confirmDisconnectAccount: false,
      selectedProject: null,
      columns: [
        {
          key: 'displayName',
          label: 'Project',
          sortable: false,
        },
        {
          key: 'plan',
          label: 'Plan',
          sortable: false,
        },
        {
          key: 'role',
          label: 'Role',
          sortable: false,
        },
        {
          key: '$actions',
          label: '',
          tdClass: 'action-cell-medium',
        },
      ],
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {
    let self = this

    self.fetchData()
  },
  methods: {
    async fetchData() {
      const self = this

      const res = await projects.getProjects()
      self.projects = res.data
    },
    getRole(project) {
      const self = this
      let myProject = self.Me.projects.find(p => p.id == project.id)

      if (!myProject) {
        return '--'
      }

      if (myProject.owner) {
        return 'Owner'
      } else {
        return myProject.role
      }
    },
    onDisconnectConfirm(project) {
      this.selectedProject = project
      this.confirmDisconnectAccount = true
    },
    onDisconnectMembership() {
      const self = this
      memberships
        .disconnectMembership(self.selectedProject.name, self.Me.id)
        .then(res => {
          self.serverErrors = []
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.confirmDisconnectAccount = false
          self.fetchData()
        })
        .catch(err => {
          if (err.response.status == 422) {
            self.HandleUnprocessableEntity(err)
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          }
        })
    },
  },
}
</script>
