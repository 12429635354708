<template>
  <div>
    <ProfileHeader />
    <b-container :fluid="false">
      <b-row class="content-header pb-1" align-h="center">
        <!-- Content Left -->
        <b-col class="content-header-left mb-2" lg="8" md="12" offset-md="0">
          <b-row class="breadcrumbs-top">
            <b-col cols="12">
              <h2 class="content-header-title float-left pr-1 mb-0">
                Account Settings
              </h2>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col lg="8" md="12" offset-md="0">
          <Profile :account="account" @update="onAccountUpdated" />
          <Projects />
          <DangerZone :account="account" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import ProfileHeader from '@/components/Layout/ProfileHeader/ProfileHeader.vue'
import Profile from './components/Profile.vue'
import DangerZone from './components/DangerZone.vue'
import { accounts } from '@/api/index'
import Projects from './components/Projects.vue'

export default {
  components: { ProfileHeader, Profile, DangerZone, Projects },
  data() {
    return {
      account: null,
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      const self = this

      await accounts.getMe().then(res => {
        self.original = res.data
        self.account = self.$_.cloneDeep(self.original)
      })
    },
    async onAccountUpdated() {
      await this.fetchData()
    },
  },
}
</script>
