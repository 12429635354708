<template>
  <div style="height: inherit">
    <div style="height: inherit" v-if="$acl.can('modelbuilder.models.manage')">
      <div class="body-content-overlay" />
      <portal to="nav-bar-left-column">
        <page-title title="Model Builder - System" subtitle="Edit the system models to manage content of system entities" />
      </portal>
      <model-edit-area
        mode="system"
        :current-id="id"
        :type="type"
        :save-enabled="isDirty"
        @content-loaded="onContentLoaded"
        v-if="models.length > 0 && id != null"
        @dirty-change="onDirtyChange"
      />
      <div class="d-flex justify-content-center align-items-center pt-1 flex-column h-75" v-if="models.length > 0 && id == null">
        <b-img fluid :src="noSelectionImageUrl" />
        <h2 class="font-weight-normal mt-2">Select a model or component on the left panel to edit it</h2>
      </div>
      <portal to="content-renderer-sidebar-left">
        <content-types-side-bar mode="system" :models="models" :current-id="id" :current-type="type" @select-item="onSelectItem" />
      </portal>
    </div>
    <div v-else>
      <forbidden />
    </div>
    <b-modal id="confirmDirtyLeave" hide-footer title="Changes" size="md" centered hide-header v-model="dirtyConfirm">
      <div class="d-block text-left mt-2">
        <h4>Unsaved changes</h4>
        <p class="h6 font-weight-normal">
          There are unsaved changes. Exiting the page the changes will be lost.
        </p>
        <p class="h6 font-weight-normal">Are you sure you want to continue?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="dirtyConfirm = false">Cancel</b-button>
        <b-button class="" variant="warning" @click="onDirtyConfirmContinue">Continue</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { contentTypes } from '@/api/index'
import ContentTypesSideBar from './components/ContentTypesSideBar.vue'
import ModelEditArea from './components/ModelEditArea.vue'
import DefaultNotifications from '@/components/Notification/default'
import Forbidden from '@/components/Forbidden/Index.vue'

export default {
  components: {
    ContentTypesSideBar,
    ModelEditArea,
    Forbidden,
  },
  data() {
    return {
      isDirty: false,
      model: {},
      models: [],
      noContentTypes: null,
      id: null,
      type: 'model',
      noSelectionImage: require('@/assets/images/pages/not-authorized.svg'),
      dirtyConfirm: false,
      to: null,
    }
  },
  async created() {
    var self = this

    self.id = self.$route.query.id ? self.$route.query.id : null
    self.type = self.$route.query.type ? self.$route.query.type : 'model'

    await self.fetchData()
  },
  computed: {
    noSelectionImageUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.noSelectionImage = require('@/assets/images/pages/coming-soon-dark.svg')
      //   return this.noSelectionImage
      // }
      return this.noSelectionImage
    },
  },
  watch: {
    $route(to, from) {
      this.id = to.query.id
      this.type = to.query.type
    },
  },
  methods: {
    async fetchData() {
      await this.fetchContentTypes()
    },
    async fetchContentTypes() {
      let modelsRes = await contentTypes.getModels(this.projectName, { system: true })
      this.models = modelsRes.data.data

      this.noContentSelected = true
    },
    onSelectItem(e) {
      this.to = {
        name: 'SystemModelBuilder',
        query: { type: e.type, id: e.item.id, project: self.projectName },
      }

      if (this.isDirty) {
        this.dirtyConfirm = true
      } else {
        this.$router.push(this.to).catch(() => {})
      }
    },
    onContentLoaded(e) {
      this.noContentSelected = false
    },
    prepareToExit() {
      if (this.isDirty) {
        const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        if (answer) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    checkDirtyDataOnExit(e) {
      if (!this.isDirty) return
      e.preventDefault()
      e.returnValue = ''
    },
    onDirtyChange(event) {
      this.isDirty = event
    },
    onDirtyConfirmContinue() {
      this.dirtyConfirm = false
      this.isDirty = false
      this.$router.push(this.to)
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.to = to
      this.dirtyConfirm = true
      next(false)
    } else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '@/assets/scss/model-builder/model-builder.scss';
@import '@/assets/scss/model-builder/model-builder-list.scss';
</style>
