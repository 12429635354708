<template>
  <component :is="tag" v-on="$listeners" class="d-flex align-items-center">
    <b-avatar size="42" alt="" class="field-avatar">
      <img :src="imageUrl" width="40%" v-if="!icon && mode !== 'system'" />
      <feather-icon :icon="icon" v-if="icon && mode !== 'system'" class="text-dark" size="18" />
      <feather-icon class="text-dark" icon="SettingsIcon" size="16" v-if="mode == 'system'" />
    </b-avatar>
    <div class="model-builder-info flex-grow-1">
      <h5 class="mb-0">
        {{ item.name }}
      </h5>
      <p class="font-small-3 card-text text-truncate">
        {{ item.description }}
      </p>
    </div>
  </component>
</template>
<script>
export default {
  name: 'ContentTypesItem',
  props: ['tag', 'type', 'item', 'mode'],
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    imageUrl() {
      return require('@/assets/images/fields/models.svg')
    },
    icon() {
      return this.item.properties && this.item.properties.icon ? `${this.item.properties.icon}Icon` : null
    },
  },
  methods: {
    select(e) {
      this.selected = e
      this.$emit('onSelectModel', {
        type: 'content-types',
        model: e,
      })
    },
  },
}
</script>
<style scoped>
.item:hover,
.item.active {
  background-color: #f8f8f8;
}
</style>
