<template>
  <b-card no-body class="p-0 bg-white text-primary">
    <b-card-header class="pl-2 pb-0 pt-1">
      <b-card-title class="">
        Assets Size
      </b-card-title>
      <b-card-text class="font-small-1 text-white mr-25 mb-0">
        <b-avatar class="bg-primary mr-25" size="32">
          <feather-icon size="18" icon="HardDriveIcon" />
        </b-avatar>
      </b-card-text>
    </b-card-header>
    <b-card-body class="p-0 d-flex flex-column justify-content-end" v-if="total">
      <b-row class="text-center mx-0 px-0 flex-grow-1">
        <b-col cols="12" class="d-flex align-items-center justify-content-center">
          <span class="font-large-4 font-weight-bolder" style="letter-spacing:-2px">{{ total | formatBytes }}</span>
        </b-col>
      </b-row>
      <b-row class="text-center mx-0 px-0 text-secondary">
        <b-col cols="4" xl="4" lg="12" md="12" class="border-top border-right d-flex align-items-between flex-column py-0 pb-50">
          <b-card-text class="mb-0">
            Images
          </b-card-text>
          <h3 class="font-weight-bolder mb-0 text-primary font-small-3">
            {{ getSize('image') | formatBytes }}
          </h3>
        </b-col>
        <b-col cols="4" xl="4" lg="12" md="12" class="border-top border-right d-flex align-items-between flex-column py-0 pb-50">
          <b-card-text class="mb-0">
            Video
          </b-card-text>
          <h3 class="font-weight-bolder mb-0 text-primary font-small-3">
            {{ getSize('video') | formatBytes }}
          </h3>
        </b-col>
        <b-col cols="4" xl="4" lg="12" md="12" class="border-top d-flex align-items-between flex-column py-0 pb-50">
          <b-card-text class="mb-0">
            Documents
          </b-card-text>
          <h3 class="font-weight-bolder mb-0 text-primary font-small-3">
            {{ getSize('document') | formatBytes }}
          </h3>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
export default {
  name: 'CounterAssets',
  props: ['data'],
  data() {
    return {}
  },
  computed: {
    total() {
      if (this.data) {
        let size = 0

        this.data.forEach(s => {
          size += s.value
        })

        return size
      } else {
        return null
      }
    },
  },
  methods: {
    getSize(type) {
      if (this.data) {
        let size = this.$_.find(this.data, a => a.key == type)

        if (size) {
          return size.value
        }
      }

      return 0
    },
  },
}
</script>
