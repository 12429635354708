<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav font-small-3" style="margin-right: 0.8rem;flex-direction: column;align-items: flex-end;">
        <p class="user-name font-weight-bolder mb-0">{{ Me.firstName }} {{ Me.lastName }}</p>
        <span class="user-status font-small-2">{{ Me.username }}</span>
      </div>
      <!-- <b-avatar size="30" variant="light-primary" badge class="badge-minimal" badge-variant="success"> -->
      <b-avatar size="36" variant="primary">
        <feather-icon icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item :to="{ name: 'ProfileSettings' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'Projects' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="RepeatIcon" class="mr-50" />
      <span>Change Project</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from 'bootstrap-vue'
// import { initialAbility } from "@/libs/acl/config";
// import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('LogOut')
      this.$router.push({ name: 'Login' })
    },
  },
}
</script>
