<template>
  <b-form class="model-builder-app-form d-flex justify-content-center">
    <new-field @field-created="$emit('field-created', $event)" :model="model" />
    <new-fieldset @fieldset-created="$emit('fieldset-created', $event)" />
  </b-form>
</template>
<script>
import NewField from '@/components/Fields/NewField.vue'
import NewFieldset from '@/components/Fields/NewFieldset.vue'
export default {
  props: ['model'],
  components: { NewField, NewFieldset },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>
