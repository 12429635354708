<template>
  <section class="model-builder-app-window">
    <div class="d-flex align-items-center justify-content-center flex-column h-50" v-if="loading">
      <div><h4>Loading...</h4></div>
      <div><b-spinner class="ml-auto mt-2" variant="primary" style="width: 3rem; height: 3rem;" /></div>
    </div>
    <div class="active-model-builder" v-if="!loading">
      <model-edit-area-header :model="current" />
      <div class="field-list">
        <fields-list :model="current" @field-update="onFieldUpdate" @field-delete="onFieldDelete" @field-duplicate="onFieldDuplicate" />
      </div>
      <model-edit-area-footer @field-created="onFieldCreated" @fieldset-created="onFieldsetCreated" :model="current" />

      <portal to="model-edit-header-buttons">
        <div class="d-flex align-items-center">
          <b-button :variant="saveButtonVariant" :disabled="!isDirty" type="submit" @click.prevent="save">Save</b-button>
          <model-edit v-model="current" :edit="true" button-text="Edit" :type="type" v-if="!current.system" @input="$emit('input', $event)" />
          <!-- <feather-icon icon="CodeIcon" size="17" class="cursor-pointer d-sm-block d-none mr-1" v-if="mode !== 'system'" /> -->
          <b-dropdown variant="link" no-caret toggle-class="p-0" right v-if="mode !== 'system'">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
            </template>
            <!-- <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item> -->

            <b-dropdown-item @click="checkDeleteType">
              <feather-icon icon="TrashIcon" class="text-danger darken-2" />
              <span class="align-middle ml-50 text-danger text-darken-2">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmDeleteAllContents = true" v-if="type == 'model'">
              <feather-icon icon="TrashIcon" class="darken-2" />
              <span class="align-middle ml-50 text-darken-2">Delete all contents</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </portal>
    </div>
    <b-modal id="confirmDeleteModel" hide-footer title="Webhook" size="md" centered hide-header v-model="confirmDeleteModel">
      <div class="d-block text-left mt-2">
        <h4>Delete Model</h4>
        <p class="h6 font-weight-normal">
          You are going to delete the model <strong>{{ current.name }}</strong
          >. The operation cannot be canceled and the contents cannot be recovered.
        </p>
        <p>Are you sure you want to continue?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDeleteModel = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDelete({ type: type, id: current.id })">Delete</b-button>
      </div>
    </b-modal>
    <b-modal id="confirmDeleteComponent" hide-footer title="Webhook" size="md" centered hide-header v-model="confirmDeleteComponent">
      <div class="d-block text-left mt-2">
        <h4>Delete Component</h4>
        <p class="h6 font-weight-normal">
          You are going to delete the component <strong>{{ current.name }}</strong
          >.
        </p>
        <p>Are you sure you want to continue?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDeleteModel = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDelete({ type: type, id: current.id })">Delete</b-button>
      </div>
    </b-modal>
    <b-modal id="confirmDeleteAllContents" hide-footer title="Webhook" size="md" centered hide-header v-model="confirmDeleteAllContents">
      <div class="d-block text-left mt-2">
        <h4>Delete Contents</h4>
        <p class="h6 font-weight-normal">
          You are going to delete all the contents of the collection <strong>{{ current.name }}</strong
          >. The operation cannot be canceled and the contents cannot be recovered.
        </p>
        <p>The operation will not delete the Model.</p>
        <p>Are you sure you want to continue?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDeleteAllContents = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDeleteAllContents">Delete</b-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { contentTypes, contents } from '@/api/index'
import FieldsList from './FieldsList.vue'
import ModelEdit from './ModelEdit.vue'
import ModelEditAreaHeader from './ModelEditAreaHeader.vue'
import ModelEditAreaFooter from './ModelEditAreaFooter.vue'
import DefaultNotifications from '@/components/Notification/default'

export default {
  props: ['currentId', 'type', 'saveEnabled', 'mode'],
  components: { FieldsList, ModelEdit, ModelEditAreaHeader, ModelEditAreaFooter },
  data() {
    return {
      perfectScrollbarSettings: { maxScrollbarLength: 150 },
      original: { attributes: [] },
      current: { attributes: [] },
      isDirty: false,
      drag: false,
      loading: false,
      modelEditOpen: false,
      confirmDeleteModel: false,
      confirmDeleteComponent: false,
      confirmDeleteAllContents: false,
    }
  },
  watch: {
    async currentId() {
      await this.fetchData()
    },
    current: {
      deep: true,
      handler(newValue) {
        this.isDirty = !this.$_.isEqual(newValue, this.original)
        this.$emit('dirty-change', this.isDirty)
      },
    },
  },
  async created() {
    if (this.currentId) {
      await this.fetchData()
    }
  },
  computed: {
    fieldsets() {
      let self = this
      let fieldsets = []

      self.$_.forEach(self.current.fieldsets, function(f) {
        let attributes = self.$_.filter(self.current.attributes, function(a) {
          return a.fieldSet === f.key
        })

        f.attributes = [...attributes]
        fieldsets.push(f)
      })

      return fieldsets
    },
    saveButtonVariant() {
      return this.isDirty ? 'primary' : 'outline-secondary'
    },
  },
  methods: {
    fetchData() {
      let self = this
      let res = {}

      let loadTimeout = setTimeout(() => {
        self.loading = true
      }, 500)

      let promise = {}

      if (self.type == 'model') {
        promise = contentTypes.getModel(self.projectName, self.currentId)
      } else if (self.type == 'component') {
        promise = contentTypes.getComponent(self.projectName, self.currentId)
      }

      promise
        .then(res => {
          self.$emit('content-loaded', { type: self.type, id: self.currentid })

          self.original = res.data
          self.current = self.$_.cloneDeep(res.data)
        })
        .finally(() => {
          clearTimeout(loadTimeout)
          self.loading = false
        })
    },
    save(e) {
      if (this.type == 'model') {
        this.saveModel()
      } else if (this.type == 'component') {
        this.saveComponent()
      }
    },
    saveModel() {
      var self = this
      contentTypes
        .updateModel(self.projectName, self.current.id, self.current)
        .then(res => {
          self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
          self.$bus.$emit('model-changed', { type: 'model', id: this.current.id, project: self.projectName })
          self.original = { ...self.current }
          self.current = self.$_.cloneDeep(self.original)
        })
        .catch(err => {
          self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
        })
    },
    saveComponent() {
      var self = this
      contentTypes
        .updateComponent(self.projectName, self.current.id, self.current)
        .then(res => {
          self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
          self.$bus.$emit('component-changed', { type: 'component', id: this.current.id, project: self.projectName })
          self.original = { ...self.current }
          self.current = self.$_.cloneDeep(self.original)
        })
        .catch(err => {
          self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
        })
    },
    onDelete(e) {
      if (this.type == 'model') {
        this.deleteModel()
      } else if (this.type == 'component') {
        this.deleteComponent()
      }
    },
    onDeleteAllContents(e) {
      var self = this
      self.loading = true
      contents
        .deleteAllContents(self.projectName, self.current.key)
        .then(res => {
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.confirmDeleteAllContents = false
          self.loading = false
        })
        .catch(err => {
          self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          self.confirmDeleteAllContents = false
          self.loading = false
        })
    },
    deleteModel() {
      var self = this
      contentTypes
        .deleteModel(self.projectName, self.current.id)
        .then(res => {
          self.$emit('content-deleted', { type: self.type, id: self.current.id, project: self.projectName })
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.confirmDeleteModel = false
        })
        .catch(err => {
          self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          self.confirmDeleteModel = false
        })
    },
    deleteComponent() {
      var self = this
      contentTypes
        .deleteComponent(self.projectName, this.current.id)
        .then(res => {
          self.$emit('content-deleted', { type: self.type, id: self.current.id, project: self.projectName })
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.confirmDeleteComponent = false
        })
        .catch(err => {
          self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          self.confirmDeleteComponent = false
        })
    },
    onFieldUpdate(e) {
      var model = { ...this.current }
      model.attributes[e.id] = e.field

      this.current = model
    },
    onFieldCreated(field) {
      var model = { ...this.current }
      model.attributes.push(field)
      this.current = model

      // setTimeout(() => {
      //   const container = this.$el.querySelector('.ps-container')
      //   container.scrollTo({
      //     top: container.scrollHeight,
      //     behavior: 'smooth',
      //   })
      // }, 50)
    },
    onFieldDelete(id) {
      //var model = { ...this.current }
      this.current.attributes.splice(id, 1)

      //this.current = model
    },
    onFieldDuplicate(ix) {
      var model = { ...this.current }

      var field = { ...this.current.attributes[ix] }
      field.label = field.label + '_copy'
      field.key = field.key + '_copy'

      model.attributes.splice(ix + 1, 0, field)

      this.current = model
    },
    onFieldsetCreated(fieldset) {
      var model = { ...this.current }
      model.fieldsets.push(fieldset)
      this.current = model
    },
    checkDeleteType() {
      if (this.type == 'model') {
        this.confirmDeleteModel = true
      } else {
        this.confirmDeleteComponent = true
      }
    },
  },
}
</script>
<style>
.list-group-item {
  padding: 0;
  background-color: none;
  border: none;
  transition: all 1s;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.field-list {
  overflow: auto;
  height: calc(100% - 65px - 65px);
}
</style>
