<template>
  <div>
    <h3>{{ data.firstName }} {{ data.lastName }}</h3>
    <h5 class="font-weight-normal">{{ data.username }}</h5>
    <div class="font-weight-normal font-small-2" v-if="data.company">{{ data.company }}</div>
    <div class="font-weight-normal font-small-2" v-if="data.jobType">{{ data.jobType }}</div>
  </div>
</template>
<script>
export default {
  props: ['data'],
}
</script>
