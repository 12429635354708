<template>
  <b-card no-body>
    <b-card-header class="pl-2">
      <b-card-title>Last Users</b-card-title>
      <!-- <b-card-text class="font-small-2 mr-25 mb-0">
          Current update
        </b-card-text> -->
    </b-card-header>

    <b-table striped hover responsive class="position-relative" :items="data" :fields="columns" v-if="data && data.length">
      <template #cell(createdAt)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(modifiedAt)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(lastName)="data">
        <router-link :to="{ name: 'EditUser', params: { id: data.item.id } }">{{ data.value }}</router-link>
      </template>
      <template #cell(isActive)="data">
        <b-badge :variant="'light-' + (data.item.isActive ? 'success' : 'dark')">{{ data.item.isActive ? 'active' : 'inactive' }}</b-badge>
      </template>
      <template #cell($actions)="data"> </template>
    </b-table>
    <div class="d-flex justify-content-center align-items-center pt-1 flex-column h-25" v-if="data && !data.length">
      <b-img :src="noDataImage" style="max-height:200px;" />
      <h2 class="font-weight-normal mb-2 mt-2">No data to display</h2>
    </div>
  </b-card>
</template>
<script>
import { users } from '@/api/index'

export default {
  name: 'LastUsers',
  props: [],
  data() {
    return {
      noDataImage: require('@/assets/images/pages/no-data.svg'),
      data: null,
      columns: [
        {
          key: 'lastName',
          label: 'Name',
          formatter: (value, key, item) => {
            return `${item.firstName} ${item.lastName}`
          },
        },
        {
          key: 'username',
          label: 'Username',
          value: 'username',
        },
        {
          key: 'createdAt',
          label: 'Created',
          value: 'createdAt',
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
        },
        {
          key: 'isActive',
          label: 'Active',
          value: 'isActive',
        },
        {
          key: '$actions',
          label: '',
          tdClass: 'action-cell',
        },
      ],
    }
  },
  computed: {},
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const self = this

      const response = await users.getUsers(self.projectName, {
        sort: 'createdAt|desc',
        size: 10,
      })
      this.data = response.data.data
    },
  },
}
</script>
