<template>
  <div>
    <b-button variant="link" @click.prevent="editModel" v-if="edit"><feather-icon icon="Edit2Icon" class="mr-25" /> {{ buttonText }}</b-button>
    <b-button @click.prevent="newModel" v-if="!edit" :variant="createButtonVariant"
      ><feather-icon icon="PlusIcon" class="mr-25" /> {{ buttonText }} {{ type == 'model' ? 'Model' : 'Component' }}</b-button
    >
    <b-modal
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-model="visible"
      hide-header
      centered
      size="lg"
      cancel-variant="outline-secondary"
      ok-title="Ok"
      cancel-title="Close"
      @ok="validateForm"
      @hide="$emit('close')"
      @hidden="onHidden"
      v-if="visible"
    >
      <b-card-body>
        <b-media>
          <template #aside>
            <b-avatar size="50" alt="" class="mb-1 field-avatar">
              <img :src="imageUrl" width="40%" v-if="!icon" />
              <feather-icon :icon="icon" v-if="icon" class="text-dark" size="18" />
            </b-avatar>
          </template>
          <h4 class="media-heading">{{ isNew ? 'Add' : 'Edit' }} {{ isModel ? 'Model' : 'Component' }}</h4>
          <b-card-text> Configure the {{ isModel ? 'Model' : 'Component' }} settings. </b-card-text>
        </b-media>
        <hr class="mt-0" />
        <b-row>
          <b-col class="cursor-pointer">
            <validation-observer ref="modelForm">
              <b-form v-on:keyup.enter="validateForm">
                <b-tabs nav-class="mb-1" v-model="activeTab" ref="modelEditTabs">
                  <b-tab title="General" :title-item-class="{ 'hidden-tab': !isModel }">
                    <b-form-group label="Name *">
                      <validation-provider #default="{ errors }" name="Name" rules="required">
                        <b-form-input
                          id="name"
                          placeholder="Posts, products, ....."
                          size="md"
                          v-model="local.name"
                          :state="errors.length > 0 ? false : null"
                          @keyup="createKey"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <b-form-text>The Name of the collection</b-form-text>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="Description">
                      <b-form-input id="description" placeholder="" size="md" v-model="local.description" />
                      <b-form-text>This description of the collection</b-form-text>
                    </b-form-group>
                    <b-form-group label="Key *">
                      <validation-provider #default="{ errors }" name="Key" rules="required|model-api-key|model-api-key-reserved-words">
                        <b-form-input
                          id="key"
                          placeholder="Posts, products, ....."
                          size="md"
                          v-model="local.key"
                          :state="errors.length > 0 ? false : null"
                          :disabled="!isNew"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <b-form-text>The name that will appear in the API endpoint and in responses</b-form-text>
                      </validation-provider>
                    </b-form-group>
                    <div v-if="isModel">
                      <section-divider text="Basic Settings" />
                      <b-form-group label="Icon" description="The icon that appear as reference on the menu">
                        <v-select id="columns" :options="icons" size="md" :value="local.properties.icon" v-if="isModel" @input="onIconChange">
                          <template v-slot:option="option">
                            <feather-icon :icon="`${option.label}Icon`" size="24" class="mr-50" />
                            {{ option.label }}
                          </template>
                          <template #selected-option="data">
                            <feather-icon :icon="`${data.label}Icon`" size="24" class="mr-50" />
                            {{ data.label }}
                          </template>
                        </v-select>
                      </b-form-group>
                      <b-form-group>
                        <div class="inline-spacing">
                          <b-form-group description="If enabled it allows to create contents in multiple languages" class="mb-0">
                            <div class="inline-spacing">
                              <b-form-checkbox id="localizable" v-model="local.localizable" class="custom-control-primary">
                                Enable localization
                              </b-form-checkbox>
                            </div>
                          </b-form-group>
                          <b-form-group description="If enabled it allows to create only 1 content in the collection" class="mb-0">
                            <div class="inline-spacing">
                              <b-form-checkbox id="required" v-model="local.isSingle" class="custom-control-primary">
                                This collection allows only 1 element
                              </b-form-checkbox>
                            </div>
                          </b-form-group>

                          <b-form-group class="mb-0">
                            <div class="inline-spacing">
                              <b-form-checkbox id="unique" v-model="local.enableStageMode" class="custom-control-primary" @change="onChangingStageMode">
                                Enable Stage Mode
                              </b-form-checkbox>
                            </div>
                            <slot name="description">
                              <small class="form-text text-muted">If enabled it allows you to publish/unpublish content of this model</small>
                              <span v-if="stageModeChangedAlert" class="text-danger"
                                >ALERT! Disabling the Stage Mode will publish all unpublished contents!</span
                              >
                            </slot>
                          </b-form-group>
                        </div>
                      </b-form-group>
                    </div>
                  </b-tab>
                  <b-tab title="Links" v-if="isModel">
                    <div class="mb-1 d-flex flex-column justify-content-start">
                      <p class="px-1 font-small-3">
                        From this tab you can provide links for content that will be shown in the edit page. For example you can add quick links to preview and
                        views in you project.
                      </p>
                      <p class="mb-1 px-1 font-small-3">
                        You can use placeholder in the link composition that will be replaced by the system. Available placeholder are
                        <b-badge pill variant="light-secondary">{_id}</b-badge> for the content id,
                        <b-badge pill variant="light-secondary">{_hash}</b-badge> for the content hash or
                        <b-badge pill variant="light-secondary">{field-api-key}</b-badge> for the value of a field.
                      </p>
                      <hr class="w-100 mb-1" />
                      <p class="text-center" v-if="!local.properties.links || local.properties.links.length == 0">no links found</p>
                      <b-row v-for="(item, index) in local.properties.links" :key="`kv-${index}`" class="p-0 mb-1">
                        <b-col cols="5" class="pr-0">
                          <text-input v-model="item.key" placeholder="Link name..." css="m-0 p-0" />
                        </b-col>
                        <b-col cols="6" class="pr-0 mr-0">
                          <text-input v-model="item.value" placeholder="Link...." css="m-0 p-0" />
                        </b-col>
                        <b-col cols="1" class="pl-0 d-flex align-items-center justify-content-start">
                          <feather-icon icon="TrashIcon" size="18" @click="onRemoveLink(index)" class="pl-25 text-danger" />
                        </b-col>
                      </b-row>
                      <b-button variant="primary" @click="onAddLink" class="m-auto"><feather-icon icon="PlusIcon" class="mr-25" /> Add Link</b-button>
                    </div>
                  </b-tab>
                </b-tabs>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-card-body>
    </b-modal>
  </div>
</template>
<script>
import { required } from '@validations'
import * as changeCase from 'change-case'
import * as validations from '@/libs/validations/validations.js'
import vSelect from 'vue-select'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  name: 'ModelEdit',
  props: {
    value: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    createButtonVariant: {
      type: String,
      default: 'flat-primary',
    },
  },
  components: { vSelect, FeatherIcon },
  data() {
    return {
      required,
      local: { properties: { icon: 'Edit', links: [] } },
      visible: false,
      autoGenerateKey: false,
      stageModeEnabled: false,
      stageModeChangedAlert: false,
      activeTab: 0,
      icons: [
        'Activity',
        'Airplay',
        'AlertCircle',
        'AlertOctagon',
        'AlertTriangle',
        'AlignCenter',
        'AlignJustify',
        'AlignLeft',
        'AlignRight',
        'Anchor',
        'Aperture',
        'Archive',
        'ArrowDownCircle',
        'ArrowDownLeft',
        'ArrowDownRight',
        'ArrowDown',
        'ArrowLeftCircle',
        'ArrowLeft',
        'ArrowRightCircle',
        'ArrowRight',
        'ArrowUpCircle',
        'ArrowUpLeft',
        'ArrowUpRight',
        'ArrowUp',
        'AtSign',
        'Award',
        'BarChart2',
        'BarChart',
        'BatteryCharging',
        'Battery',
        'BellOff',
        'Bell',
        'Bluetooth',
        'Bold',
        'BookOpen',
        'Book',
        'Bookmark',
        'Box',
        'Briefcase',
        'Calendar',
        'CameraOff',
        'Camera',
        'Cast',
        'CheckCircle',
        'CheckSquare',
        'Check',
        'ChevronDown',
        'ChevronLeft',
        'ChevronRight',
        'ChevronUp',
        'ChevronsDown',
        'ChevronsLeft',
        'ChevronsRight',
        'ChevronsUp',
        'Chrome',
        'Circle',
        'Clipboard',
        'Clock',
        'CloudDrizzle',
        'CloudLightning',
        'CloudOff',
        'CloudRain',
        'CloudSnow',
        'Cloud',
        'Code',
        'Codepen',
        'Codesandbox',
        'Coffee',
        'Columns',
        'Command',
        'Compass',
        'Copy',
        'CornerDownLeft',
        'CornerDownRight',
        'CornerLeftDown',
        'CornerLeftUp',
        'CornerRightDown',
        'CornerRightUp',
        'CornerUpLeft',
        'CornerUpRight',
        'Cpu',
        'CreditCard',
        'Crop',
        'Crosshair',
        'Database',
        'Delete',
        'Disc',
        'DivideCircle',
        'DivideSquare',
        'Divide',
        'DollarSign',
        'DownloadCloud',
        'Download',
        'Dribbble',
        'Droplet',
        'Edit2',
        'Edit3',
        'Edit',
        'ExternalLink',
        'EyeOff',
        'Eye',
        'Facebook',
        'FastForward',
        'Feather',
        'Figma',
        'FileMinus',
        'FilePlus',
        'FileText',
        'File',
        'Film',
        'Filter',
        'Flag',
        'FolderMinus',
        'FolderPlus',
        'Folder',
        'Framer',
        'Frown',
        'Gift',
        'GitBranch',
        'GitCommit',
        'GitMerge',
        'GitPullRequest',
        'Github',
        'Gitlab',
        'Globe',
        'Grid',
        'HardDrive',
        'Hash',
        'Headphones',
        'Heart',
        'HelpCircle',
        'Hexagon',
        'Home',
        'Image',
        'Inbox',
        'Info',
        'Instagram',
        'Italic',
        'Key',
        'Layers',
        'Layout',
        'LifeBuoy',
        'Link2',
        'Link',
        'Linkedin',
        'List',
        'Loader',
        'Lock',
        'LogIn',
        'LogOut',
        'Mail',
        'MapPin',
        'Map',
        'Maximize2',
        'Maximize',
        'Meh',
        'Menu',
        'MessageCircle',
        'MessageSquare',
        'MicOff',
        'Mic',
        'Minimize2',
        'Minimize',
        'MinusCircle',
        'MinusSquare',
        'Minus',
        'Monitor',
        'Moon',
        'MoreHorizontal',
        'MoreVertical',
        'MousePointer',
        'Move',
        'Music',
        'Navigation2',
        'Navigation',
        'Octagon',
        'Package',
        'Paperclip',
        'PauseCircle',
        'Pause',
        'PenTool',
        'Percent',
        'PhoneCall',
        'PhoneForwarded',
        'PhoneIncoming',
        'PhoneMissed',
        'PhoneOff',
        'PhoneOutgoing',
        'Phone',
        'PieChart',
        'PlayCircle',
        'Play',
        'PlusCircle',
        'PlusSquare',
        'Plus',
        'Pocket',
        'Power',
        'Printer',
        'Radio',
        'RefreshCcw',
        'RefreshCw',
        'Repeat',
        'Rewind',
        'RotateCcw',
        'RotateCw',
        'Rss',
        'Save',
        'Scissors',
        'Search',
        'Send',
        'Server',
        'Settings',
        'Share2',
        'Share',
        'ShieldOff',
        'Shield',
        'ShoppingBag',
        'ShoppingCart',
        'Shuffle',
        'Sidebar',
        'SkipBack',
        'SkipForward',
        'Slack',
        'Slash',
        'Sliders',
        'Smartphone',
        'Smile',
        'Speaker',
        'Square',
        'Star',
        'StopCircle',
        'Sun',
        'Sunrise',
        'Sunset',
        'Tablet',
        'Tag',
        'Target',
        'Terminal',
        'Thermometer',
        'ThumbsDown',
        'ThumbsUp',
        'ToggleLeft',
        'ToggleRight',
        'Tool',
        'Trash2',
        'Trash',
        'Trello',
        'TrendingDown',
        'TrendingUp',
        'Triangle',
        'Truck',
        'Tv',
        'Twitch',
        'Twitter',
        'Type',
        'Umbrella',
        'Underline',
        'Unlock',
        'UploadCloud',
        'Upload',
        'UserCheck',
        'UserMinus',
        'UserPlus',
        'UserX',
        'User',
        'Users',
        'VideoOff',
        'Video',
        'Voicemail',
        'Volume1',
        'Volume2',
        'VolumeX',
        'Volume',
        'Watch',
        'WifiOff',
        'Wifi',
        'Wind',
        'XCircle',
        'XOctagon',
        'XSquare',
        'X',
        'Youtube',
        'ZapOff',
        'Zap',
        'ZoomIn',
        'ZoomOut',
      ],
    }
  },
  created() {},
  watch: {
    open: {
      immediate: true,
      handler(newValue, oldValue) {
        this.visible = newValue
      },
    },
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.local = newValue ? this.$_.cloneDeep(newValue) : { label: '' }
        this.stageModeEnabled = this.local.enableStageMode
        if (this.stageModeEnabled) this.stageModeChangedAlert = false

        if (this.isModel) {
          if (!this.local.properties) {
            this.local.properties = {}
          }
          if (!this.local.properties.icon) {
            this.local.properties.icon = 'Edit'
          }
          if (!this.local.properties.icon) {
            this.local.properties.links = []
          }
        }
      },
    },
  },
  computed: {
    isNew() {
      return this.local.id ? false : true
    },
    isModel() {
      return this.type == 'model'
    },
    imageUrl() {
      return require('@/assets/images/fields/models.svg')
    },
    icon() {
      const self = this
      return self.local.properties && self.local.properties.icon ? `${self.local.properties.icon}Icon` : null
    },
  },
  methods: {
    onHidden() {
      this.visible = false
      this.activeTab = 0
    },
    onIconChange(e) {
      const self = this
      self.local.properties.icon = e
      if (this.isNew) self.$emit('input', { ...self.local })
    },

    editModel() {
      this.visible = true
      this.autoGenerateKey = this.isNew
    },
    newModel() {
      this.local = { id: null, name: '', key: '' }

      if (this.isModel) {
        this.local.properties = { icon: 'Edit', links: [] }
      }

      this.visible = true
      this.autoGenerateKey = this.isNew
    },
    createKey(e) {
      if (this.autoGenerateKey) {
        this.local.key = changeCase.paramCase(this.local.name)
      }
    },
    onChangingStageMode(e) {
      this.stageModeChangedAlert = !this.isNew && this.stageModeEnabled == true && e == false
    },
    onAddLink() {
      const self = this
      if (!self.local.properties.links) {
        self.local.properties.links = []
      }

      self.local.properties.links.push({ key: '', value: '' })

      if (this.isNew) self.$emit('input', { ...self.local })
    },
    onRemoveLink(index) {
      const self = this

      self.local.properties.links.splice(index, 1)

      if (this.isNew) self.$emit('input', { ...self.local })
    },
    async validateForm(e) {
      e.preventDefault()
      var self = this
      return await self.$refs.modelForm.validate().then(success => {
        if (success) {
          this.visible = false

          self.$emit('input', { ...self.local })

          if (this.isNew) {
            self.$emit('new-content-type', { type: this.type, item: self.local })
          }

          return true
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style>
.hidden-tab {
  display: none;
}
</style>
