<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>
          <b-media-aside class="mr-2">
            <div class="d-flex align-items-center">
              <img :src="require('@/assets/images/logo/logo-icon.svg')" width="24" class="mr-1" />

              <b-card-title>Resources</b-card-title>
            </div>
          </b-media-aside>
        </b-card-title>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body class="pt-1">
      <b-row>
        <b-col v-for="item in browserData" :key="item.browserImg" xl="4" lg="6" class="mb-2">
          <b-media no-body>
            <b-avatar size="36" variant="light-info" class="mr-1">
              <feather-icon size="18" icon="LayersIcon" />
            </b-avatar>
            <div>
              <a :href="item.link" :target="item.target"
                ><h6 class="mb-0">
                  {{ item.name }}
                </h6>
                <span class="font-small-2 text-dark">{{ item.content }}</span>
              </a>
            </div>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      browserData: [
        {
          browserImg: require('@/assets/images/icons/google-chrome.png'),
          name: 'Documentation',
          content: 'Check out the documentation to start working with it',
          link: 'https://docs.atlascms.io',
          target: '_blank',
        },
        {
          browserImg: require('@/assets/images/icons/mozila-firefox.png'),
          name: 'Tutorials',
          content: 'Follow the Tutorials to implement your project',
          link: 'https://docs.atlascms.io',
          target: '_blank',
        },
        {
          browserImg: require('@/assets/images/icons/apple-safari.png'),
          name: 'SDK',
          content: 'User the SDK to speed-up your development',
          link: 'https://docs.atlascms.io',
          target: '_blank',
        },
        // {
        //   browserImg: require('@/assets/images/icons/internet-explorer.png'),
        //   name: 'Code Generator',
        //   usage: '4.2%',
        // },
        // {
        //   browserImg: require('@/assets/images/icons/opera.png'),
        //   name: 'Opera Mini',
        //   usage: '8.4%',
        // },
      ],
    }
  },
  created() {},
}
</script>
