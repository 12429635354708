<template>
  <b-navbar :toggleable="false" class="mb-3 header-navbar navbar navbar-shadow align-items-center bg-black py-1" style="max-height:62px">
    <b-container :fluid="true">
      <b-row class="justify-content-md-start px-0 py-50 w-100" align-v="center" no-gutters>
        <b-col lg="12" md="12">
          <div class="d-flex w-100 justify-content-start align-items-center">
            <router-link to="/" class="ml-2 mr-4 inline-block z-5 svg-link">
              <atlas-logo type="light" width="150" />
            </router-link>
            <router-link :to="{ name: 'ProfileSettings' }" class="text-white mr-2">
              <FeatherIcon icon="UserIcon" size="22" class="mr-25" /> My Profile
            </router-link>
            <router-link :to="{ name: 'Projects' }" class="text-white mr-2 flex-grow-1">
              <FeatherIcon icon="FolderIcon" size="22" class="mr-25" /> My Projects
            </router-link>

            <b-navbar-nav class="nav align-items-center mr-2">
              <UserDropdown />
            </b-navbar-nav>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-navbar>
</template>
<script>
import AtlasLogo from '@/components/Layout/Logo/AtlasLogo.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import UserDropdown from '@/layouts/components/UserDropdown.vue'
export default {
  components: { AtlasLogo, FeatherIcon, UserDropdown },
  computed: {},
}
</script>
