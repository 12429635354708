<template>
  <div>
    <b-container fluid class="p-0">
      <b-card no-body>
        <b-card-body>
          <b-card-title>
            Danger zone
          </b-card-title>
          <b-row align-v="center">
            <b-col lg="8" md="12" class="text-center text-md-left">
              <div class="pb-1">If you want to delete your account, click the following button. This cannot be undone.</div>
              <b-button variant="danger" class="mr-25" size="lg" @click="callToDelete = true">Delete Account</b-button>
            </b-col>
          </b-row>
          <b-row align-v="center" v-if="callToDelete">
            <b-col lg="12" md="12" class="text-center text-md-left text-danger mt-1">
              Self Account deletion is not available at the moment. Please send an email <a href="mailto:support@atlascms.io">support@atlascms.io</a> specifying
              your user name. Thank you.
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import DefaultNotifications from '@/components/Notification/default'
import { accounts } from '@/api/index'

export default {
  name: 'ProfileAccount',
  components: {},
  mixins: [],
  data() {
    return {
      loading: false,
      callToDelete: false,
      projects: [],
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {
    let self = this

    self.fetchData()
  },
  methods: {
    async fetchData() {
      const self = this
    },
  },
}
</script>
