<template>
  <b-container fluid="md" class="mb-1">
    <div class="p-2 dragging-container col col-md-12 col-lg-12 col-xl-10 mx-auto" :class="{ dragging: drag }">
      <draggable
        v-bind="dragOptions"
        v-model="model.attributes"
        handle=".drag-handle"
        @start="onDragFieldStart"
        @end="onDragFieldEnd"
        :group="{ name: 'fields' }"
      >
        <div v-for="(field, ix_field) in model.attributes" :key="'field_' + ix_field">
          <!-- {{ current.attributes[index] }} -->
          <field-settings
            :model="model"
            :field="field"
            :id="ix_field"
            @field-update="$emit('field-update', $event)"
            @field-delete="$emit('field-delete', $event)"
            @field-duplicate="$emit('field-duplicate', $event)"
          />
        </div>
      </draggable>
    </div>
  </b-container>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: ['model'],
  components: { draggable },
  data() {
    return {
      perfectScrollbarSettings: { maxScrollbarLength: 150, swipeEasing: true },
      drag: false,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'fields',
        disabled: false,
        ghostClass: 'model-builder-field-ghost',
        scrollSensitivity: 200,
        forceFallback: true,
      }
    },
  },
  watch: {
    model: {
      immediate: true,
      handler(newValue, oldValue) {
        const self = this
        if (!newValue.localizable) {
          self.$_.forEach(self.model.attributes, function(f) {
            f.localizable = false
          })
        }
      },
    },
  },
  methods: {
    onDragFieldEnd(e) {
      let self = this

      this.drag = false

      let counter = 1

      self.$_.each(self.model.attributes, function(f) {
        f.order = counter
        counter += 1
      })
    },
    onDragFieldStart(e) {
      this.drag = true
    },
  },
}
</script>
<style lang="scss">
.dragging-container {
  border: 4px dashed transparent;
  transition: border-color 0.2s ease-in-out;
  border-radius: 1rem;
}

.dragging {
  border: 4px dashed #82868baa;
}
</style>
