<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content">
        <span class="sidebar-close-icon">
          <feather-icon icon="XIcon" size="16" @click="$emit('update:mq-shall-show-left-sidebar', false)" />
        </span>
        <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="model-builder-user-list-wrapper list-group scroll-area">
          <b-tabs nav-class="mb-2" @activate-tab="onActivateTab">
            <b-tab title="Models" :active="currentType == 'model' || !currentType" v-if="$acl.can('modelbuilder.models.manage')">
              <ul class="model-builder-users-list chat-list media-list">
                <content-types-item
                  tag="li"
                  v-for="model in models"
                  :key="model.id"
                  :item="model"
                  :mode="mode"
                  :class="{ active: currentId === model.id }"
                  @click.prevent="$emit('select-item', { type: 'model', item: model })"
                />
              </ul>

              <!-- Header -->
            </b-tab>
            <b-tab title="Components" :active="currentType == 'component'" v-if="mode !== 'system' && $acl.can('modelbuilder.components.manage')">
              <b-card-text>
                <ul class="model-builder-users-list model-builder-list media-list">
                  <content-types-item
                    tag="li"
                    v-for="component in components"
                    :key="component.id"
                    :item="component"
                    :class="{ active: currentId === component.id }"
                    @click.prevent="$emit('select-item', { type: 'component', item: component })"
                  />
                </ul>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </vue-perfect-scrollbar>
        <b-form class="model-builder-sidebar-form d-flex justify-content-between" v-if="$acl.can('modelbuilder.models.manage')">
          <div>
            <router-link v-b-tooltip.hover title="System Models" :to="{ name: 'SystemModelBuilder' }" class="mt-1 text-center" v-if="mode !== 'system'"
              ><feather-icon icon="SettingsIcon" size="16"
            /></router-link>
            <router-link v-b-tooltip.hover title="User Defined Models" :to="{ name: 'ModelBuilder' }" class="mt-1 text-center" v-if="mode == 'system'"
              ><feather-icon icon="LayersIcon" size="16"
            /></router-link>
          </div>
          <div class="align-right">
            <model-edit v-model="model" @new-content-type="$emit('new-content-type', $event)" :type="activeType" button-text="Add" v-if="mode !== 'system'" />
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ContentTypesItem from './ContentTypesItem.vue'
import ModelEdit from './ModelEdit.vue'

export default {
  props: ['currentId', 'currentType', 'models', 'components', 'mode'],
  components: {
    ContentTypesItem,
    ModelEdit,
    VuePerfectScrollbar,
  },
  data() {
    return {
      perfectScrollbarSettings: { maxScrollbarLength: 150 },
      model: {},
      activeType: 'model',
    }
  },
  created() {
    this.prepareModel()
  },
  methods: {
    prepareModel() {
      if (this.activeType == 'component') {
        this.model = {}
      } else {
        this.model = { properties: { icon: 'Edit', links: [] } }
      }
    },
    onActivateTab(newIx, prevIx, e) {
      if (newIx == 1) {
        this.activeType = 'component'
      } else {
        this.activeType = 'model'
      }

      this.prepareModel()
    },
  },
}
</script>
