<template>
  <b-card no-body class="p-0">
    <b-card-header class=" pl-2">
      <b-card-title> Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Current update
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body pl-2" v-if="counters">
      <b-row>
        <b-col v-for="(item, index) in counters" :key="`item.icon_${index}`" xl="3" sm="6" :class="item.customClass">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h3 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import { computed } from 'vue2-dropzone'

export default {
  name: 'CounterStatistics',
  props: ['data'],
  data() {
    return {}
  },
  computed: {
    counters() {
      if (this.data) {
        return [
          {
            icon: 'EditIcon',
            color: 'light-primary',
            title: this.data.contents,
            subtitle: 'Contents',
            customClass: 'mb-2',
          },
          {
            icon: 'ImageIcon',
            color: 'light-success',
            title: this.data.images,
            subtitle: 'Images',
            customClass: 'mb-2',
          },
          {
            icon: 'VideoIcon',
            color: 'light-info',
            title: this.data.videos,
            subtitle: 'Video',
            customClass: 'mb-2',
          },
          {
            icon: 'FileIcon',
            color: 'light-danger',
            title: this.data.documents,
            subtitle: 'Documents',
            customClass: 'mb-2',
          },
          {
            icon: 'LayersIcon',
            color: 'primary',
            title: this.data.models,
            subtitle: 'Models',
            customClass: 'mb-2',
          },
          {
            icon: 'UserIcon',
            color: 'warning',
            title: this.data.users,
            subtitle: 'Users',
            customClass: 'mb-2',
          },
          {
            icon: 'FlagIcon',
            color: 'success',
            title: this.data.locales,
            subtitle: 'Locales',
            customClass: 'mb-2',
          },
        ]
      } else {
        return null
      }
    },
  },
}
</script>
